/* eslint-disable react/prop-types */
import React from 'react';
import LandingBanner from '../components/banner';
import RepaymentCalculatorComplex from '../../../components/repaymentCalculatorComplex';
import ProofPoints from '../../../components/proofPoints';
import TestimonialsComponent from '../../../components/testimonials';
import FAQComponent from '../../../components/faq';
import EndOfPage from '../components/endOfPage/endOfPage.component';

const NimbleMediumLoan = ({ componentData }) => {
  const bannerData = componentData.filter(
    item => item.__typename === 'ContentfulNimblePageHeadingSection'
  )[0];
  const calculatorData = componentData.filter(
    item => item.__typename === 'ContentfulNimbleRepaymentCalculatorComplex'
  )[0];
  const proofPointsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentProofPoints'
  )[0];
  const testimonialsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentTestimonials'
  )[0];
  const FAQsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentFaq'
  )[0];
  return (
    <>
      {bannerData && <LandingBanner data={bannerData} />}
      {calculatorData && <RepaymentCalculatorComplex data={calculatorData} />}
      {proofPointsData && <ProofPoints data={proofPointsData} />}
      {testimonialsData && <TestimonialsComponent data={testimonialsData} />}
      {FAQsData && <FAQComponent data={FAQsData} />}
      <EndOfPage
        applyLink="https://nimble.com.au/cxindex.html"
        applyName="Nimble Medium Personal Loan"
      />
    </>
  );
};

export default NimbleMediumLoan;
